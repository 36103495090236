import CaterDialog from "../../../components/CaterDialog";
import {useState} from "react";
import AddressForm from "./AddressForm";
import {useDispatch, useSelector} from "react-redux";
import {setErrorMessage, setModal, setSearchInput} from "./store";
import Alert from "../../../components/Alert";

const Address = () => {
    const dispatch = useDispatch();
    const addressState = useSelector((state) => state.address);

    const handleCloseModal = () => {
        if (!addressState.data.address) {
            dispatch(setErrorMessage(true));
        } else {
            dispatch(setErrorMessage(false));
            dispatch(setSearchInput(false));
            dispatch(setModal(false));
        }

    }
    return (
        <>
          <CaterDialog isOpen={addressState.showModal === undefined ? true : addressState.showModal} onCloseEvent={handleCloseModal}  title="Delivery Address" className={"!max-w-xl"}>
              {addressState.errorMessage && (
                  <Alert type={"danger"} className={"mb-2"}>Address entry is required to continue.</Alert>
              )}

            <AddressForm/>
          </CaterDialog>
        </>
    )
}

export default Address;
