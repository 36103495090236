import ApiService from "./ApiService";

export async function listCatering(data) {
    return ApiService.fetchData({
        url: '/catering/list',
        method: 'post',
        data,
    })
}

export async function listCateringCorporate(data) {
    return ApiService.fetchData({
        url: '/catering/list-corporate',
        method: 'post',
        data,
    })
}

export async function getCatering(data) {
    return ApiService.fetchData({
        url: '/catering/get',
        method: 'post',
        data,
    })
}

export async function getProduct(data) {
    return ApiService.fetchData({
        url: '/catering/get-product',
        method: 'post',
        data
    })
}
