import Feature from "./feature";
import Faq from "./faq";
import CTA from "./cta";
import Testimonials from "./testimonials";
import Hero from "./hero";

function Home() {
    const space = " py-16 lg:py-24 ";

    return (
        <div className={"mt-5"}>
            <Hero/>
            <Feature space={space}/>
            <CTA space={space}/>
            <Testimonials space={space}/>
            <Faq space={space}/>
        </div>


    );
}

export default Home;