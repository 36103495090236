import {lazy, Suspense} from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {StoreProvider} from "./context/storeContext";
import {useSelector} from "react-redux";
import './App.css';
import {routes} from "./routes"

//pages
import Header from "./layouts/header";
import Spinner from "./components/Spinner";
import Cart from "./pages/Order/Cart";
import Home from "./pages/WebSite/Home";
import WebsiteHeader from "./layouts/website-header";
import Claim from "./pages/Legal/Claim";
import Privacy from "./pages/Legal/Privacy";
import Terms from "./pages/Legal/Terms";
import About from "./pages/Legal/About";

const PrivateRoute = ({element}) => {
    const {logged} = useSelector((state) => state.auth);

    if (!logged) {
        return <Navigate to={"/home"}/>
    } else {
        return element;
    }
}

export default function App() {
    return (
        <StoreProvider>
            <BrowserRouter>
                <Suspense fallback={
                    <div className="flex items-center justify-center h-screen space-x-2">
                        <Spinner isspining={true} size={40}/>
                        <p>Loading page...</p>
                    </div>
                }>
                    <Routes>
                        <Route element={<WebsiteHeader/>}>
                            <Route path={"/"} element={<Home/>}/>
                            <Route path={"/claim"} element={<Claim/>}/>
                            <Route path={"/privacy"} element={<Privacy/>}/>
                            <Route path={"/terms"} element={<Terms/>}/>
                            <Route path={"/about"} element={<About/>}/>
                        </Route>

                        <Route element={<Header/>}>
                            {routes.map((route) => (
                                route.authRequired ? (
                                        <Route
                                            key={route.path}
                                            index={route.index}
                                            path={route.path}
                                            element={<PrivateRoute element={<route.component/>}/>}
                                        />

                                    ) :
                                    (
                                        <Route
                                            key={route.path}
                                            index={route.index}
                                            path={route.path}
                                            element={<route.component/>}
                                        />
                                    )
                            ))}
                        </Route>
                    </Routes>
                    <Cart/>
                </Suspense>
            </BrowserRouter>
        </StoreProvider>
    )
}


