import ApiService from "./ApiService";

export async function apiCreatePayment(data) {
    return ApiService.fetchData({
        url: '/payment/create-payment',
        method: 'post',
        data,
    })
}

export async function apiUpdatePayment(data) {
    return ApiService.fetchData({
        url: '/payment/update-payment',
        method: 'post',
        data,
    })
}

export async function apiDeliveryFee(data) {
    return ApiService.fetchData({
        url: '/payment/delivery-fee',
        method: 'post',
        data
    })
}

export async function apiCheckPayId(data) {
    return ApiService.fetchData({
        url: '/payment/check-pay-id',
        method: 'post',
        data,
    })
}