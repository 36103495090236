import React, { useState, useEffect } from 'react';
import moment from "moment-timezone"

const CountdownTimer = ({ targetDate, additionalClassName, overTimeText, title }) => {
    const [timeLeft, setTimeLeft] = useState({});
    const [timerColor, setTimerColor] = useState('text-black'); // Default color

    useEffect(() => {
        const calculateTimeLeft = () => {
            const target = moment.utc(targetDate);
            const now = moment.tz("America/Los_Angeles");
            const duration = moment.duration(target.diff(now));
            let timeLeft = {};

            if (duration > 0) {
                timeLeft = {
                    d: duration.days(),
                    h: duration.hours(),
                    m: duration.minutes(),
                    s: duration.seconds(),
                };
            }

            return timeLeft;
        };

        const updateColor = (difference) => {
            const hoursLeft = difference / (1000 * 60 * 60);
            if (hoursLeft <= 0.5) { // Less than 30 minutes
                setTimerColor('text-red-500');
            } else if (hoursLeft <= 2) { // Less than 2 hours
                setTimerColor('text-orange-500');
            } else {
                setTimerColor('text-black');
            }
        };

        const timer = setInterval(() => {
            const timeLeft = calculateTimeLeft();
            setTimeLeft(timeLeft);
            updateColor(+new Date(targetDate).getTime() - +new Date().getTime());
        }, 1100);

        return () => clearInterval(timer);
    }, [targetDate]);

    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
            return;
        }

        timerComponents.push(
            <span key={interval}>
        {timeLeft[interval]} {interval}{" "}
      </span>
        );
    });

    return (
        <div className={`text-center mb-3 ${timerColor} text-xl ${additionalClassName}`}>
            <p className={"text-sm text-gray-600"}>{title}</p>
            {timerComponents.length ? timerComponents : <span>{overTimeText}</span>}
        </div>
    );
};

export default CountdownTimer;
