import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    corporateCaterList,
    corporateCreateOrder,
    corporateGetOrder, corporateGetSmartOrder,
    corporateListOrder, corporateSmartOrder, corporateSummaryListOrder, corporateUpdateOrder,
    corporateWeekly,
} from "../../../service/CorporateService";


const initialState = {
    weeklyLoading: false,
    employeeLoading: false,
    orderLoading: false,
    cateringLoading: false,
    orderSummaryLoading: false,
    weekly: [],
    weeklyFilter: [],
    orderList: [],
    orderSummaryList: [],
    currentUser: "",
    currentOrder: {},
    employee: [],
    invoices: [],
    caterings: []
}

export const listWeekly = createAsyncThunk(
    'corporate/listWeekly',
    async (data) => {
        const response = await corporateWeekly(data);
        return response.data;
    }
);

export const listCorporateOrder = createAsyncThunk(
    'corporate/listCorporateOrder',
    async (data) => {
        const response = await corporateListOrder(data);
        return response.data;
    }
);

export const listCorporateSummaryOrder = createAsyncThunk(
    'corporate/listCorporateSummaryOrder',
    async () => {
        const response = await corporateSummaryListOrder()
        return response.data;
    }
);

export const getCorporateOrder = createAsyncThunk(
    'corporate/getCorporateOrder',
    async (data) => {
        const response = await corporateGetOrder(data);
        return response.data;
    }
);

export const createCorporateOrder = createAsyncThunk(
    'corporate/createCorporateOrder',
    async (data) => {
        const response = await corporateCreateOrder(data);
        return response.data;
    }
);

export const updateCorporateOrder = createAsyncThunk(
    'corporate/updateCorporateOrder',
    async (data) => {
        const response = await corporateUpdateOrder(data);
        return response.data;
    }
);

export const listCorporateCatering = createAsyncThunk(
    'corporate/corporateCateringList',
    async (data) => {
        const response = await corporateCaterList(data);
        return response.data;
    }
);

export const smartOrderCorporate = createAsyncThunk(
    'corporate/smartOrderCorporate',
    async (data) => {
        const response = await corporateSmartOrder(data);
        return response.data;
    }
);


const corporateSlice = createSlice({
    name: 'corporate',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setEmployeeLoading: (state, action) => {
            state.employeeLoading = action.payload
        },
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload
        },
        setCurrentOrder: (state, action) => {
            state.currentOrder = action.payload
        },
        updateWeekly: (state, action) => {
            state.weekly = action.payload
        },
        setWeeklyFilter: (state, action) => {
            state.weeklyFilter = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(listWeekly.fulfilled, (state, action) => {
                state.weeklyLoading = false
                state.weekly = action.payload.data
            })
            .addCase(listWeekly.pending, (state, action) => {
                state.weeklyLoading = true
            })
            .addCase(listWeekly.rejected, (state, action) => {
                state.weeklyLoading = false
            })
            .addCase(getCorporateOrder.fulfilled, (state, action) => {
                state.orderLoading = false
                state.currentOrder = action.payload.data
            })
            .addCase(getCorporateOrder.pending, (state, action) => {
                state.orderLoading = true
            })
            .addCase(getCorporateOrder.rejected, (state, action) => {
                state.orderLoading = false
            })
            .addCase(createCorporateOrder.fulfilled, (state, action) => {
                state.orderLoading = false
                state.currentOrder = action.payload.data
            })
            .addCase(createCorporateOrder.pending, (state, action) => {
                state.orderLoading = true
            })
            .addCase(createCorporateOrder.rejected, (state, action) => {
                state.orderLoading = false
            })
            .addCase(updateCorporateOrder.fulfilled, (state, action) => {
                state.orderLoading = false
                state.currentOrder = action.payload.data
            })
            .addCase(updateCorporateOrder.pending, (state, action) => {
                state.orderLoading = true
            })
            .addCase(updateCorporateOrder.rejected, (state, action) => {
                state.orderLoading = false
            })
            .addCase(smartOrderCorporate.fulfilled, (state, action) => {
                state.orderLoading = false
                state.currentOrder = action.payload.data
            })
            .addCase(smartOrderCorporate.pending, (state, action) => {
                state.orderLoading = true
            })
            .addCase(smartOrderCorporate.rejected, (state, action) => {
                state.orderLoading = false
            })
            .addCase(listCorporateOrder.fulfilled, (state, action) => {
                state.orderLoading = false
                state.orderList = action.payload.data
            })
            .addCase(listCorporateOrder.pending, (state, action) => {
                state.orderLoading = true
            })
            .addCase(listCorporateOrder.rejected, (state, action) => {
                state.orderLoading = false
            })
            .addCase(listCorporateCatering.fulfilled, (state, action) => {
                state.cateringLoading = false
                state.caterings = action.payload.data
            })
            .addCase(listCorporateCatering.pending, (state, action) => {
                state.cateringLoading = true
            })
            .addCase(listCorporateCatering.rejected, (state, action) => {
                state.cateringLoading = false
            })
            .addCase(listCorporateSummaryOrder.fulfilled, (state, action) => {
                state.orderSummaryLoading = false
                state.orderSummaryList = action.payload.data
            })
            .addCase(listCorporateSummaryOrder.pending, (state, action) => {
                state.orderSummaryLoading = true
            })
            .addCase(listCorporateSummaryOrder.rejected, (state, action) => {
                state.orderSummaryLoading = false
            })
    }
})

export const {
    setLoading,
    setCurrentUser,
    setCurrentOrder,
    setEmployeeLoading,
    updateWeekly,
    setWeeklyFilter
} = corporateSlice.actions

export default corporateSlice.reducer

