import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {apiListCard} from "../../../service/AuthService";

const initialState = {
    cardModal: false,
    cardsLoading: false,
    cards: [],
    primaryCard: ""
}

export const listCards = createAsyncThunk(
    'listCards',
    async () => {
        const response = await apiListCard();
        return response.data
    }
)

const profile = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setCardModal: (state, action) => {
            state.cardModal = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(listCards.fulfilled, (state, action) => {
                state.cardsLoading = false;
                state.cards = action.payload.data;
                state.primaryCard = action.payload.primary;
            })
            .addCase(listCards.rejected, (state, action) => {
                state.cardsLoading = false;
            })
            .addCase(listCards.pending, (state, action) => {
                state.cardsLoading = true;
            })
    }
});

export const {
    setCardModal
} = profile.actions

export default profile.reducer
