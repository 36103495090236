import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getCatering, listCatering, listCateringCorporate} from "../../../service/CateringService";


export const getListCatering = createAsyncThunk(
    'getListCatering',
    async (data) => {
        const response = await listCatering(data)
        return response.data
    }
)

export const getListCateringCorporate = createAsyncThunk(
    'getListCateringCorporate',
    async (data) => {
        const response = await listCateringCorporate(data)
        return response.data
    }
)

export const getGetCatering = createAsyncThunk(
    'getCatering',
    async (data) => {
        const response = await getCatering(data)
        return response.data
    }
)


const initialState = {
    loading: false,
    selectedData: {},
    data: [],
    filteredData: [],
    recommendedBranch: []
}

const cateringSlice = createSlice({
    name: 'catering',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setFiltered: (state, action) => {
            state.filteredData = action.payload
        },
        setFavorite: (state, action) => {
            state.selectedData.favorite = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListCatering.fulfilled, (state, action) => {
                state.loading = false
                state.data = action.payload.data
                state.filteredData = action.payload.data
            })
            .addCase(getListCatering.pending, (state) => {
                state.loading = true
            })
            .addCase(getListCatering.rejected, (state) => {
                state.loading = false
            })
            .addCase(getListCateringCorporate.fulfilled, (state, action) => {
                state.loading = false
                state.data = action.payload.data
                state.filteredData = action.payload.data
                state.recommendedBranch = action.payload.recommendedList
            })
            .addCase(getListCateringCorporate.pending, (state) => {
                state.loading = true
            })
            .addCase(getListCateringCorporate.rejected, (state) => {
                state.loading = false
            })
            .addCase(getGetCatering.fulfilled, (state, action) => {
                state.loading = false
                state.selectedData = action.payload.data
            })
            .addCase(getGetCatering.pending, (state) => {
                state.loading = true
            })
            .addCase(getGetCatering.rejected, (state, action) => {
                state.loading = false
            })
    }
})


export const {
    setLoading,
    setFiltered,
    setFavorite
} = cateringSlice.actions

export default cateringSlice.reducer
