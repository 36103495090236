import React, {createContext, useContext} from "react";
import { appStore, persistor} from "../store/";

const StoreContext = createContext();

const StoreProvider = ({children}) => (
    <StoreContext.Provider value={{appStore, persistor}}>
        {children}
    </StoreContext.Provider>
);

const useStore = () => useContext(StoreContext);

export {StoreProvider, useStore}
