import {GlobeAmericasIcon, HandThumbUpIcon, MagnifyingGlassIcon} from '@heroicons/react/20/solid'

export const HomeText = {
  heroTitle: "Are you ready to eat?",
  heroBackground: "https://images.unsplash.com/photo-1531234799389-dcb7651eb0a2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  searchPlaceHolder: "Enter your delivery address...",
  searchButton: "Search",
  feature: {
    subTitle: "CaterValley",
    title: "The reasons for best choice",
    image: "https://images.unsplash.com/photo-1554997433-8e233c02c751?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    description: "Only a few of the reasons for the best choice, only in CaterValley, the strongest, the most modern.",
    sections: [
      {
        title: "Extensive Catering Network.",
        description: "CaterValley brings together numerous catering companies on a single platform, providing users with a wide range of food options.",
        icon: GlobeAmericasIcon
      },
      {
        title: "Diverse Culinary Experiences.",
        description: "CaterValley curates diverse and rich culinary experiences by showcasing the specialty dishes of different catering companies.",
        icon: HandThumbUpIcon
      },
      {
        title: "Quick and Easy Search.",
        description: "Users can swiftly and easily search for their desired meals based on location, catering company, or cuisine type.",
        icon: MagnifyingGlassIcon
      }
    ],
  },
  references: {
    firstLogo: "https://tailwindui.com/img/logos/158x48/transistor-logo-gray-900.svg",
    secondLogo: "https://tailwindui.com/img/logos/158x48/reform-logo-gray-900.svg",
    thirdLogo: "https://tailwindui.com/img/logos/158x48/tuple-logo-gray-900.svg",
    fourthLogo: "https://tailwindui.com/img/logos/158x48/savvycal-logo-gray-900.svg",
    fifthLogo: "https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg",
    barText: "Over 2500 companies use our tools to better their business."
  },
  stats: {
    title: "We see CaterValley as a source of enrichment in our lives and a value addition to the world",
    description: "Diam nunc lacus lacus aliquam turpis enim. Eget hac velit est euismod lacus. Est non placerat nam arcu. Cras purus nibh cursus sit eu in id. Integer vel nibh.",
    firstStats: {
      title: "Users on the platform",
      description: "Vel labore deleniti veniam consequuntur sunt nobis.",
      value: "250k"
    },
    secondStats: {
      title: "We’re proud that our customers have made over $8 billion in total revenue.",
      description: "Eu duis porta aliquam ornare. Elementum eget magna egestas.",
      value: "$8.9 billion"
    },
    thirdStats: {
      title: "Transactions this year",
      description: "Eu duis porta aliquam ornare. Elementum eget magna egestas. Eu duis porta aliquam ornare.",
      value: "401,093"
    }
  },
  cta: {
    title: "Are you catering?",
    subTitle: "Are you catering? Are you a caterer? But I prefer would you like to partner with us? Or would you like to be one of our merchants?",
    description: "All you need to do is get in touch with us. Our prompt customer support will always be delighted to assist you.",
    actionButton: "More",
    actionButtonLink: "https://merchant.catervalley.com",
    image: {
      firstImage: "https://images.unsplash.com/photo-1532635211-8ec15f2ce05c?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      secondImage: "https://images.unsplash.com/photo-1532635224-cf024e66d122?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      thirdImage: "https://images.unsplash.com/photo-1600961459395-62759980991e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      fourthImage: "https://images.unsplash.com/photo-1554997433-8e233c02c751?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    }
  },
  testimonials: {
    title: "Comments",
    description: "We have worked with thousands of amazing people",
    comments: [
      {
        comment: "CaterValley made selecting catering for our business events a breeze. The diverse options and user-friendly interface left everyone satisfied.",
        name: "Emily Mitchell",
        image: "/image/comment/emily.jpg"
      },
      {
        comment: "CaterValley impressed me with its diverse range of caterers. Exploring unique cuisines became an enjoyable experience, and the platform's convenience enhanced everything.",
        name: "Daniel Rodriguez",
        image: "/image/comment/daniel.jpg"
      },
      {
        comment: "For those mindful of a healthy diet, CaterValley is truly a blessing. Easily finding nutritious options and customizing orders to fit my preferences is a valuable feature.",
        name: "Sarah Turner",
        image: "/image/comment/sarah.jpg"
      },
      {
        comment: "CaterValley is indispensable for my business travels. The quick setup and quality options allow me to focus on my work, ensuring everyone enjoys delicious meals.",
        name: "James Thompson",
        image: "/image/comment/james.jpg"
      },
      {
        comment: "Event planning becomes much simpler with CaterValley. Connecting with top catering companies is seamless, saving time, and the food quality consistently earns praise from attendees.",
        name: "Olivia Hayes",
        image: "/image/comment/olivia.jpg"
      },
      {
        comment: "CaterValley transforms every meal into an adventure. I've discovered hidden gems and made my dining experience delightful and full of surprises.",
        name: "David Foster",
        image: "/image/comment/david.jpg"
      },
    ]
  },
  faq: {
    title: "Frequently asked questions",
    faqs: [
      {
        question: "Why choose CaterValley?",
        answer: "CaterValley offers a wide catering network, providing rich food options, quick and easy search capabilities, and diverse taste experiences."
      },
      {
        question: "How does CaterValley work?",
        answer: "Catering companies add their meals to the system, and users can select and order their desired meals by searching based on location, catering company, or cuisine type."
      },
      {
        question: "What can I find on CaterValley?",
        answer: "CaterValley curates diverse and rich culinary experiences by bringing together the specialty dishes of different catering companies."
      },
      {
        question: "How can I choose and evaluate catering companies on CaterValley?",
        answer: "On the CaterValley platform, you can select catering companies by reviewing their profiles, checking user reviews, and assessing their previous order experiences."
      },
      {
        question: "What specific advantages does CaterValley offer to users?",
        answer: "CaterValley provides users with a diverse range of food options through an extensive catering network. With its quick and easy search features, you can find your desired meals and take advantage of special promotions or discounts."
      },
      {
        question: "What payment methods are available on CaterValley, and how is the security of payments ensured?",
        answer: "CaterValley offers various secure payment options, including credit cards and online payments. The platform ensures the security of payments by employing modern security protocols to protect user information."
      }
    ]
  }





}