import {MapPinIcon} from '@heroicons/react/20/solid'
import {HomeText} from "../../pages/WebSite/Home/text";
import {useDispatch} from "react-redux";
import usePlacesAutocompleteService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import CaterFormInput from "../CaterFormInput/CaterFormInput";
import {IoLocationOutline} from 'react-icons/io5';
import {TiArrowRightOutline} from 'react-icons/ti';
import {useNavigate} from "react-router-dom";
import {setAddress} from "../../pages/Modals/Address/store";

const apikey = "AIzaSyCFNFtYje__a04wEZEuv88O3oNwCR602Ac";

export default function SearchButton() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        placesService,
        placePredictions,
        getPlacePredictions,
    } = usePlacesAutocompleteService({
        apiKey: apikey,
        libraries: ["places"],
        options: {
            componentRestrictions: {country: ['US']},
            types: ['geocode']
        },
        language: "en"
    });

    const getAddressFullInfo = async (placeId) => {
        return new Promise((resolve, reject) => {
            if (placesService) {
                placesService.getDetails({placeId}, resolve);
            } else {
                reject(new Error("Places service not available"));
            }
        });
    };

    return (
        <div className="relative">
            <div className="mt-2 flex shadow-sm">
                <div className="relative flex-grow h-12 focus-within:z-30">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <MapPinIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                    </div>
                    <CaterFormInput
                        className="block w-full border-none !ring-0 !focus:ring-0 py-1.5 pl-10 text-gray-900 placeholder:text-gray-400 text-xs h-12 lg:text-sm"
                        type="search"
                        name="search-location"
                        id="search-bar"
                        placeholder={HomeText.searchPlaceHolder}
                        onChange={(evt) => {
                            getPlacePredictions({input: evt.target.value});
                        }}
                    />
                    <div className="absolute w-full mt-0.5 max-h-60 overflow-auto bg-white shadow-lg z-50 rounded-xl">
                        {placePredictions.map((item, index) => (
                            <div
                                key={index}
                                className="px-3 py-3 w-full border-b  flex items-center gap-5 hover:bg-gray-50 cursor-pointer active:bg-gray-200"
                                onClick={async () => {
                                    const addressInformation = await getAddressFullInfo(item.place_id);
                                    dispatch(setAddress({
                                            title: "",
                                            address: item.structured_formatting.main_text,
                                            city: item.terms[item.terms.length - 3]?.value,
                                            state: item.terms[item.terms.length - 2]?.value,
                                            placeId: item.place_id,
                                    }));
                                    navigate("/home");
                                }}
                            >
                                <IoLocationOutline className="text-lg"/>
                                <div className="ml-5">
                                    <p className="font-semibold">{item.structured_formatting.main_text}</p>
                                    <p className="text-gray-500">{item.structured_formatting.secondary_text}</p>
                                </div>
                                <TiArrowRightOutline className="ml-auto text-lg"/>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
