import {HomeText} from "../text";
import SearchButton from "../../../../components/SearchButton";

export default function Hero() {
    return(
        <div>
            <div className={"relative w-full"}>
                <div className={"flex h-96 justify-center items-center"}>
                    <img
                        src={HomeText.heroBackground}
                        alt={"catervalley-hero"}
                        className={"rounded-2xl h-96 w-full object-cover brightness-50"}
                    />

                    <div className={"absolute w-full px-5"}>
                        <div className={"grid grid-cols-1 lg:grid-cols-2 gap-4"}>
                            <div>
                                <h1 className={"mb-2 lg:mb-4 font-bold text-white text-3xl lg:text-5xl"}>{HomeText.heroTitle}</h1>
                                <SearchButton/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}