import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    logged: false,
    showModal: false,
    page: {
        activeTab: "login"
    },
    data: {
        session: {
            accessToken: "",
            refreshToken: ""
        },
        user: {},
        branch: {}
    }
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state, action) => {
            state.logged = true
            state.data = action.payload
        },
        logout: () => initialState,
        setAuthModal: (state, action) => {
            state.showModal = action.payload
        },
        setAccessToken: (state, actions) => {
            state.data.session.accessToken = actions.payload
        },
        setActiveTab: (state, action) => {
            state.page.activeTab = action.payload
        },
        setUpdateUser: (state, action) => {
            state.data.user = action.payload
        }
    }
})

export const {
    login,
    logout,
    setAccessToken,
    setAuthModal,
    setActiveTab,
    setUpdateUser
} = authSlice.actions;

export default authSlice.reducer;