import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getProduct} from "../../../../service/CateringService";

export const getGetProduct = createAsyncThunk(
    'getProduct',
    async (data) => {
        const response = await getProduct(data)
        return response.data
    }
)


const initialState = {
    loading: false,
    showModal: false,
    basketItem: {},
    basketItemTotalPrice: 0,
    basketItemCount: 1,
    data: {}
}

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setModal: (state, action) => {
            state.showModal = action.payload
        },
        setBasketItem: (state, action) => {
            state.basketItem = action.payload
        },
        setBasketItemTotalPrice: (state, action) => {
            state.basketItemTotalPrice = action.payload
        },
        setBasketItemCount: (state, action) => {
            state.basketItemCount = action.payload <= 0 ? 1 : action.payload
        },
        setData: (state, action) => {
            state.data = action.payload
        },
        setBasketItemNote: (state, action) => {
            state.basketItem.itemMeta.note = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getGetProduct.fulfilled, (state, action) => {
                state.loading = false
                state.data = action.payload.data
            })
            .addCase(getGetProduct.pending, (state) => {
                state.loading = true
            })
            .addCase(getGetProduct.rejected, (state) => {
                state.loading = false
            })
    }
})

export const {
    setModal,
    setBasketItem,
    setBasketItemTotalPrice,
    setBasketItemCount,
    setData,
    setBasketItemNote
} = productSlice.actions;

export default productSlice.reducer
