import React, {Fragment, useRef} from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

const CaterDialog = (props) => {
    const {
        title,
        children,
        className,
        isOpen,
        onCloseEvent,
        initialFocusRef,
        ...rest
    } = props;

    const initialFocusRefObject = useRef(null);

    return (
        <Transition appear show={isOpen === undefined ? false : isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-40" onClose={onCloseEvent} initialFocus={initialFocusRefObject}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 z-50 overflow-y-auto mt-10 lg:mt-2">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className={`w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all ${className ? className : ""}`}
                            >
                                <Dialog.Title as="h3" className="text-xl leading-6 text-gray-900">
                                    {title}
                                </Dialog.Title>

                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                                        onClick={onCloseEvent}

                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>

                                <div className="mt-2" ref={initialFocusRefObject}>
                                    {children}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

CaterDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onCloseEvent: PropTypes.func.isRequired,
    initialFocus: PropTypes.object,
    title: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
};

export default CaterDialog;
