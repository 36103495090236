import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    showCartPanel: false,
    draftOrder: {},
    draftOrderList: []
}

const draftOrderSlice = createSlice({
    name: 'draftOrder',
    initialState,
    reducers: {
       setItems: (state, action) => {
           state.draftOrder.draftOrderItems = action.payload
       },
       setDraftOrder: (state, action) => {
           state.draftOrder = action.payload
       },
       setDraftOrderList: (state, action) => {
           state.draftOrderList = action.payload
       },
       setShowCartPanel: (state, action) => {
           state.showCartPanel = action.payload
       },
       resetDraftOrderState: () => initialState
    }
})

export const {
    setItems,
    setShowCartPanel,
    setDraftOrder,
    setDraftOrderList,
    resetDraftOrderState
} = draftOrderSlice.actions

export default draftOrderSlice.reducer
