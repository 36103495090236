const Privacy = () => {
    return (
        <div>
            <div className={"py-4 "}>
                <img
                    alt={"cover"}
                    src={"https://api.catervalley.com/uploads/RA32F8ANI7Y7SL9D16H4.png"}
                    className={"w-full h-72 object-cover brightness-[.5] rounded-xl"}
                />

                <div className={"py-4"}>
                    <h1 className={"text-2xl font-bold"}>Privacy Policy</h1>
                </div>

                <div>
                    <p><strong>Effective Date 08/07/ </strong><strong>2024</strong></p><br></br>
                    <p>Thank you for choosing Catervalley Company ("Catervalley", "we", "us", "our"). We are committed
                        to protecting your privacy and personal information. This Privacy Policy explains how we
                        collect, use, share, and protect information about you.</p>
                    <p>By using our website (<a href="http://www.catervalley.com">www.catervalley.com</a>), mobile
                        applications, and related services (collectively, the "Platform"), you agree to the practices
                        described in this Privacy Policy and our Terms of Use.</p>
                    <p>For questions about our privacy practices, please contact us at <a
                        href="mailto:privacy@catervalley.com">privacy@catervalley.com</a>.</p>
                    <ol>
                        <br></br>
                        <li><strong><u>THE INFORMATION WE COLLECT</u></strong></li>
                    </ol>
                    <p>We collect information directly from you, automatically through your use of our Platform, and
                        from third parties.</p>
                    <ol>
                        <br></br>
                        <li><strong> Information You Provide to Us</strong></li>
                    </ol>
                    <ul>
                        <li>Food-Ordering Users:</li>
                        <li>Sign-up information (name, email, phone number, industry, login credentials)</li>
                        <li>Order details (food provider, items, quantities, dietary restrictions, delivery information,
                            payment details)
                        </li>
                        <li>User content (reviews, photos, ratings, feedback)</li>
                        <li>Communications with us</li>
                        <li>Food Providers:</li>
                        <li>Business information (legal name, entity name, locations, addresses, website URLs)</li>
                        <li>Menu details, pricing, delivery options</li>
                        <li>Legal and regulatory documentation</li>
                        <li>Organizations:</li>
                        <li>Contact and business information for corporate accounts</li>
                        <li>Other voluntary information you choose to provide</li>
                    </ul>
                    <ol>
                        <br></br>
                        <li><strong> Information We Collect Automatically</strong></li>
                    </ol>
                    <ul>
                        <li>Usage Data: Access times, features used, pages visited, content uploads/posts, error logs
                        </li>
                        <li>Device Data: IP address, hardware details, operating system, language preferences, unique
                            identifiers
                        </li>
                        <li>Location Information: General location based on IP address, precise GPS location (with
                            permission)
                        </li>
                        <li>Cookies and Similar Technologies: Information collected through cookies, web beacons, and
                            similar technologies
                        </li>
                    </ul>
                    <ol>
                        <br></br>
                        <li><strong> Information We Collect from Others</strong></li>
                    </ol>
                    <ul>
                        <li>Food and delivery service providers</li>
                        <li>Third-party login services (e.g., social media accounts)</li>
                        <li>Business partners, marketers, researchers, and other third parties</li>
                    </ul>
                    <ol start="2">
                        <br></br>
                        <li><strong><u>HOW WE USE THE INFORMATION WE COLLECT</u></strong></li>
                    </ol>
                    <p>We use your information for the following purposes:</p>
                    <ul>
                        <li>Providing, improving, and promoting our Platform</li>
                        <li>Analyzing Platform usage</li>
                        <li>Communicating with you about our services, marketing, and promotions</li>
                        <li>Facilitating transactions, deliveries, and payments</li>
                        <li>Offering customer support</li>
                        <li>Conducting research and analysis</li>
                        <li>Detecting and preventing fraud</li>
                        <li>Resolving disputes and enforcing our legal terms</li>
                        <li>Complying with legal obligations</li>
                    </ul>
                    <p>We may combine information from different sources and use aggregated or de-identified data for
                        various purposes, including research and marketing.</p>
                    <ol start="3">
                        <br></br>
                        <li><strong><u>ONLINE AND MOBILE APP ADVERTISING</u></strong></li>
                        <br></br>
                        <li><strong> Online Advertising</strong></li>
                    </ol>
                    <p>We may use third-party advertising technologies that use cookies and similar methods to deliver
                        relevant ads to you on our Platform and other websites. These ads may be based on your browsing
                        history and other information we have about you.</p>
                    <p>We may associate cookies with de-identified data, such as your hashed email address, to tailor
                        advertisements more effectively.</p>
                    <p>To learn more about tailored browser advertising and how to opt out:</p>
                    <ul>
                        <li>Visit the Network Advertising Initiative's Consumer Opt-Out link</li>
                        <li>Visit the Digital Advertising Alliance's Consumer Opt-Out link</li>
                        <li>For Google display network ads, visit the Google Ads Settings page</li>
                    </ul>
                    <p>Please note that these opt-outs are browser and device-specific.</p>
                    <ol>
                        <br></br>
                        <li><strong> Mobile App Advertising</strong></li>
                    </ol>
                    <p>When using our mobile app, you may receive tailored in-app advertisements. Each operating system
                        (iOS, Android, Windows) provides its own instructions on how to opt out of personalized in-app
                        advertisements.</p>
                    <ol>
                        <br></br>
                        <li><strong> Notice Concerning Do Not Track</strong></li>
                    </ol>
                    <p>While we are committed to providing you choices regarding the information collected on our
                        website for third-party purposes, we currently do not respond to browser-initiated Do Not Track
                        (DNT) signals.</p>
                    <ol start="4">
                        <br></br>
                        <li><strong><u>HOW WE DISCLOSE THE INFORMATION WE COLLECT</u></strong></li>
                    </ol>
                    <p>At Catervalley Company, we and our service providers disclose the information collected from and
                        about you for specific business and operational purposes, including but not limited to:</p>
                    <ol>
                        <li><strong>Food Providers:</strong> Your information, including name, contact details, dietary
                            preferences and restrictions provided by you, and purchase details, may be shared with food
                            providers with whom you place orders, as well as their affiliates. It's important to note
                            that the information disclosed to food providers is not governed by this Privacy Policy;
                            instead, it is subject to the food provider's privacy policy. This may allow the food
                            provider to engage in marketing communications and promotional activities, among other
                            things.
                        </li>
                        <br></br>
                        <li><strong>Food Recipients:</strong> In cases where you are not the recipient of a food order
                            you place, we may disclose your information to the food recipient to the extent that
                            communication with them is necessary regarding the food order.
                        </li>
                        <br></br>
                        <li><strong>Your Employer:</strong> If you place orders on behalf of your employer and your
                            employer has a corporate account with Catervalley or has engaged Catervalley to handle food
                            orders for its employees, customers, or others, we may disclose your information to your
                            employer.
                        </li>
                        <br></br>
                        <li><strong>Service Providers:</strong> Catervalley may provide access to or disclose your
                            information to third-party service providers who perform various functions on our behalf.
                            These service providers may include entities responsible for billing, payment processing,
                            advertising, web analytics, data storage and processing, customer support, delivery
                            services, security, fraud prevention, and other related services.
                        </li>
                        <br></br>
                        <li>Catervalley Company may provide access to or disclose your information to its affiliates as
                            described in this Privacy Policy. This disclosure allows for operational coordination,
                            service improvement, marketing initiatives with your consent, and compliance with legal
                            obligations. It's important to note that this disclosure is subject to strict
                            confidentiality and data protection measures to safeguard your privacy and ensure compliance
                            with applicable laws.
                        </li>
                        <br></br>
                        <li><strong>For Legal Reasons or the Protection: </strong>Catervalley Company is committed to
                            protecting the rights, property, and personal safety of our users and others. In certain
                            situations, we may need to disclose the information we collect about you if required by law
                            or legal process, or if we believe in good faith that disclosure is reasonably necessary to:
                            <ul>
                                <br></br>
                                <li><strong>Enforce Terms of Use and Privacy Policy:</strong> We may disclose your
                                    information to enforce our Terms of Use, this Privacy Policy, or other contracts
                                    with you. This includes investigating potential violations of these agreements and
                                    taking appropriate actions to address any breaches.
                                </li>
                                <br></br>
                                <li><strong>Respond to Claims of Content Violations:</strong> If there are claims that
                                    any content posted on our platform violates the rights of third parties, we may
                                    disclose relevant information to address these claims and ensure compliance with
                                    intellectual property rights and other legal obligations.
                                </li>
                                <br></br>
                                <li><strong>Protect Rights and Safety:</strong> Our priority is to protect the rights,
                                    property, and personal safety of Catervalley, our platform users, and others. In
                                    situations where there is a threat or potential harm, we may disclose information to
                                    prevent or mitigate such risks.
                                </li>
                                <br></br>
                                <li><strong>Fraud Protection and Spam/Malware Prevention:</strong> We may exchange
                                    information with other companies and organizations for purposes such as fraud
                                    protection, spam/malware prevention, and similar security measures.
                                </li>
                            </ul>
                        </li>
                    </ol>
                    <p>.</p>
                    <ol start="7">
                        <br></br>
                        <li><strong>Business Transfers.</strong>If Catervalley Company undergoes a sale, transfer,
                            merger, reorganization, or similar event involving our business, your information may be
                            among the transferred assets. This includes data collected from or about you as part of our
                            normal business operations. We will comply with applicable laws and regulations regarding
                            such transfers, ensuring that your rights and privacy are protected. Any significant changes
                            or new privacy practices resulting from a business transfer will be communicated to you
                            through our platform, website, or email. Rest assured, we remain committed to protecting
                            your information and handling it securely, regardless of any business changes. If you have
                            questions, feel free to contact us for clarification.
                        </li>
                    </ol>
                    <p>&nbsp;</p>
                    <ol start="8">
                        <li><strong>Other Unaffiliated Third Parties.</strong>Catervalley Company may disclose your
                            information to other unaffiliated third parties for various purposes, including:
                        </li>
                    </ol>
                    <p><strong>&nbsp;</strong></p>
                    <ul>
                        <br></br>
                        <li><strong>Collaborative Efforts:</strong> We may collaborate with third parties in
                            integration, co-marketing, and other coordinated efforts. This could involve sharing
                            relevant information to enhance our services or promote mutual benefits.
                        </li>
                        <br></br>
                        <li><strong>Valuable Offers:</strong> Your information may be disclosed to third parties whose
                            offers we believe you may find valuable. This could include promotions, deals, or services
                            that align with your interests and preferences.
                        </li>
                        <br></br>
                        <li><strong>Data Co-ops:</strong> We may participate in data co-ops that allow us to share
                            certain information about our users, such as email addresses, with other participating
                            companies. This exchange of data helps enhance customer records, identify potential
                            customers, and facilitate targeted marketing efforts.
                        </li>
                    </ul>
                    <p>It's important to note that while we may share information with these unaffiliated third parties,
                        we do not disclose personal information collected solely through our SMS text programs to
                        unaffiliated third parties for their own marketing purposes. This ensures that your privacy and
                        preferences are respected, particularly in specific communication channels like SMS. We
                        prioritize transparency and the protection of your information in all our collaborations and
                        disclosures to third parties.</p>
                    <ol start="9">
                        <br></br>
                        <li><strong>Your Consent.</strong>If you provide consent for us to disclose your information, we
                            will do so in accordance with your consent preferences. This ensures that your preferences
                            regarding the disclosure of your information are respected and followed.
                        </li>
                    </ol>
                    <p>We may disclose aggregate or de-identified information to any third party, including advertisers,
                        promotional partners, and sponsors, at our discretion.</p>
                    <ol start="5">
                        <br></br>
                        <li><strong><u>SPECIAL PROVISIONS FOR PHONE NUMBERS AND CONSENT</u></strong></li>
                        <br></br>
                        <li><strong> Collection and Use of Phone Numbers</strong></li>
                    </ol>
                    <ul>
                        <li>We collect phone numbers directly from users during account creation, order placement, or
                            when opting into our communication services.
                        </li>
                        <br></br>
                        <li>Phone numbers are used solely for:
                            <ul>
                                <li>Account verification and security</li>
                                <li>Order notifications and updates</li>
                                <li>Customer support communications</li>
                                <li>Delivering requested SMS-based services or information</li>
                            </ul>
                        </li>
                    </ul>
                    <ol>
                        <br></br>
                        <li><strong> Consent Management</strong></li>
                    </ol>
                    <ul>
                        <li>We obtain explicit consent from users before sending any marketing or promotional messages
                            to their phone numbers.
                        </li>
                        <li>Users can opt in or out of different types of communications independently (e.g., order
                            notifications, marketing messages).
                        </li>
                        <li>We maintain detailed records of user consent, including the date, time, and specific
                            services agreed to.
                        </li>
                    </ul>
                    <ol>
                        <br></br>
                        <li><strong> Protection and Non-Disclosure of Phone Numbers and Consent</strong></li>
                    </ol>
                    <ul>
                        <li>Phone numbers and consent records are stored securely in our systems with restricted
                            access.
                        </li>
                        <br></br>
                        <li>We do not sell, rent, trade, or otherwise share phone numbers or consent information with
                            any third parties, including:
                            <ul>
                                <li>Advertisers</li>
                                <li>Marketing partners</li>
                                <li>Data brokers</li>
                                <li>Other service providers</li>
                            </ul>
                        </li>
                        <br></br>
                        <li>This non-disclosure policy applies even in cases where we may share other types of user data
                            as outlined elsewhere in this privacy policy.
                        </li>
                    </ul>
                    <ol>
                        <br></br>
                        <li><strong> Limited Internal Access</strong></li>
                    </ol>
                    <ul>
                        <li>Access to phone numbers and consent data within our organization is strictly limited to
                            employees who require this information to perform essential job functions.
                        </li>
                        <li>All employees with such access are bound by confidentiality agreements and receive regular
                            privacy training.
                        </li>
                    </ul>
                    <ol>
                        <br></br>
                        <li><strong> Retention and Deletion</strong></li>
                    </ol>
                    <ul>
                        <li>We retain phone numbers and consent records only for as long as necessary to provide our
                            services or comply with legal obligations.
                        </li>
                        <li>Users can request deletion of their phone number and consent records at any time, subject to
                            any overriding legal retention requirements.
                        </li>
                    </ul>
                    <ol>
                        <br></br>
                        <li><strong> Security Measures</strong></li>
                    </ol>
                    <ul>
                        <li>Phone numbers and consent data are encrypted both in transit and at rest.</li>
                        <li>We employ industry-standard security measures to protect this information from unauthorized
                            access, alteration, or destruction.
                        </li>
                    </ul>
                    <ol>
                        <br></br>
                        <li><strong> Compliance with Regulations</strong></li>
                    </ol>
                    <ul>
                        <li>Our handling of phone numbers and consent data complies with all applicable laws and
                            regulations, including but not limited to:
                            <ul>
                                <li>The Telephone Consumer Protection Act (TCPA)</li>
                                <li>The CAN-SPAM Act</li>
                                <li>Applicable state-level privacy laws</li>
                            </ul>
                        </li>
                    </ul>
                    <ol>
                        <br></br>
                        <li><strong> Transparency and User Control</strong></li>
                    </ol>
                    <ul>
                        <li>Users can view and manage their communication preferences at any time through their account
                            settings.
                        </li>
                        <li>We provide clear, easily accessible instructions for opting out of any or all
                            communications.
                        </li>
                    </ul>
                    <ol>
                        <br></br>
                        <li><strong> Third-Party Service Providers</strong></li>
                    </ol>
                    <ul>
                        <li>While we may use third-party service providers to help deliver SMS messages or manage our
                            communication systems, these providers:
                            <ul>
                                <li>Do not have permission to use phone numbers or consent data for any purpose other
                                    than providing services to us
                                </li>
                                <li>Are contractually obligated to maintain the confidentiality and security of this
                                    information
                                </li>
                                <li>Do not have the right to retain, use, or disclose this information for their own
                                    purposes
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ol>
                        <br></br>
                        <li><strong> Notification of Changes</strong></li>
                    </ol>
                    <ul>
                        <li>We will notify users of any changes to how we handle phone numbers or consent data through
                            updates to this privacy policy and, where appropriate, direct communications to affected
                            users.
                        </li>
                    </ul>
                    <ol start="6">
                        <br></br>
                        <li><strong><u>YOUR RIGHTS AND CHOICES</u></strong></li>
                    </ol>
                    <p>In addition to the other rights and choices described in this Privacy Policy, you have these
                        rights and choices regarding your information:</p>
                    <ol>
                        <br></br>
                        <li><strong> Rights Regarding Your Information</strong></li>
                    </ol>
                    <p>Depending on your jurisdiction, you may have the right to make certain requests regarding
                        your &ldquo;personal information&rdquo; or &ldquo;personal data&rdquo; (as such terms are
                        defined under applicable law, and collectively referred to herein as &ldquo;personal
                        information&rdquo;). These rights are extended to all individuals, regardless of residency,
                        subject to this Privacy Policy. Specifically, you have the right to:</p>
                    <ul>
                        <br></br>
                        <li><strong>Information Request:</strong></li>
                    </ul>
                    <ul>
                        <li>You have the right to request information about the categories of personal information we
                            collect or disclose about you.
                        </li>
                        <li>You can inquire about the sources of such information, the business or commercial purpose
                            for collecting your personal information, and the categories of third parties with whom we
                            share your information.
                            <ul>
                                <br></br>
                                <li><strong>Access and Copy:</strong></li>
                            </ul>
                        </li>
                        <li>You can request access to and/or a copy of specific personal information we hold about you.
                            This allows you to review the data we have and ensure its accuracy.
                            <ul>
                                <br></br>
                                <li><strong>Correction or Update:</strong></li>
                            </ul>
                        </li>
                        <li>If you believe any of your personal information we hold is inaccurate or incomplete, you
                            have the right to request corrections or updates to it.
                            <ul>
                                <br></br>
                                <li><strong>Deletion:</strong></li>
                            </ul>
                        </li>
                        <li>You have the right to request the deletion of certain personal information we have about
                            you. This includes data that is no longer necessary for the purposes for which it was
                            collected or processed.
                            <ul>
                                <br></br>
                                <li><strong>Financial Incentives:</strong></li>
                            </ul>
                        </li>
                        <li>If we offer any financial incentives tied to your personal information, you have the right
                            to receive information about these incentives.
                            <ul>
                                <br></br>
                                <li><strong>Opt-Out:</strong></li>
                            </ul>
                        </li>
                        <li>You can opt out of the processing of your personal information for purposes of profiling
                            that may result in legal or similarly significant effects, where applicable. This gives you
                            control over how your information is used in decision-making processes.
                        </li>
                    </ul>
                    <br></br>
                    <p><strong>Opt-Out Rights and Non-Discrimination</strong></p>
                    <p>You have the right to opt out of the "sales" of your information and the "sharing/use of your
                        information for targeted advertising." This opt-out option allows you to control how your
                        information is used for marketing purposes.</p>
                    <p>Under applicable law, you also have the right to not be discriminated against for exercising your
                        rights. This means that we cannot treat you differently or deny you services based on your
                        choice to exercise your privacy rights.</p>
                    <br></br>
                    <p><strong>Exemptions and Verification</strong></p>
                    <p>Certain information may be exempt from your requests under applicable law. For example, we must
                        retain certain information to provide our services to you effectively.</p>
                    <p>We also take reasonable steps to verify your identity before responding to your request. This
                        verification process may include verifying your name and email address, depending on the
                        sensitivity of the information you're requesting and the type of request you're making.</p>
                    <p>You can also designate an authorized agent to submit requests on your behalf. To verify an
                        authorized agent, you must provide written permission or a power of attorney to make such
                        requests.</p>
                    <br></br>
                    <p><strong>Appeal Process</strong></p>
                    <p>If your request is denied, depending on applicable law, you may have the right to appeal our
                        decision. We will provide information on how to appeal the decision in our communications with
                        you.</p>
                    <p>For further information about your legal rights or to exercise any of these rights, please
                        contact us at support@Catervalley.com. You can also log into your account to correct or update
                        your personal information through your account settings.</p>
                    <p>We are committed to ensuring that your rights regarding your information are respected and that
                        any requests or appeals are handled promptly and in accordance with applicable laws and
                        regulations.</p>
                    <ol>
                        <br></br>
                        <li><strong> Opting Out of Marketing Emails</strong></li>
                    </ol>
                    <p>To opt out of receiving marketing emails from us, you have two options:</p>
                    <ol>
                        <br></br>
                        <li><strong>Unsubscribe Link:</strong> You can click on the unsubscribe link provided in our
                            marketing emails. This will direct you to a page where you can confirm your decision to
                            unsubscribe.
                        </li>
                        <br></br>
                        <li><strong>Profile Page:</strong> Alternatively, you can update your marketing preferences
                            directly on your Profile page. This allows you to manage your communication preferences
                            according to your preferences.
                        </li>
                    </ol>
                    <p>Please note that it may take us some time to process your request, as we strive to comply with
                        our legal obligations regarding opt-out requests.</p>
                    <p>Even if you opt out of receiving marketing messages, you will still receive important
                        administrative messages from us. These include order confirmations, updates to our policies and
                        practices, or other communications related to our relationship or transactions with you. This
                        ensures that you stay informed about essential information regarding your interactions with
                        us.</p>
                    <ol><br></br>
                        <li><strong> Notice of Right to Opt Out of Sales of Personal Information and Processing/Sharing
                            of Personal Information for Targeted Advertising Purposes</strong></li>
                    </ol>
                    <p>As explained in the &ldquo;Other Unaffiliated Third Parties&rdquo; provision in section 4 above,
                        we sometimes disclose information to unaffiliated third parties we collaborate with or that
                        provide offers that we think may be of value to you, or with data cooperatives. This disclosure
                        of information may be considered a &ldquo;sale&rdquo; under applicable laws.</p>
                    <p>We also provide personal information to third-party advertising providers for targeted
                        advertising purposes, so that we can provide you with more relevant and tailored ads regarding
                        our services, or use analytics partners to assist us in analyzing use of our services and our
                        user/customer base. The disclosure of your personal information to these third parties to assist
                        us in providing these services may be considered a &ldquo;sale&rdquo; of personal information
                        under applicable law, or, the processing/sharing of personal information for targeted
                        advertising purposes.</p>
                    <br></br>
                    <p>If you would like to opt out of our online disclosure such as through cookie and pixel technology
                        of your personal information for purposes that could be considered &ldquo;sales&rdquo; for those
                        third parties' own commercial purposes, or &ldquo;sharing&rdquo; for purposes of targeted
                        advertising. You can also submit a request to opt out of our offline
                        disclosures of information that are subject to applicable opt out rights by. Please note that we
                        do not knowingly
                        sell the personal information of minors under 16 years of age without legally-required
                        affirmative authorization.</p>
                    <br></br>
                    <p>Please note that if you have a legally-recognized browser-based opt out preference signal turned
                        on via your device browser, we recognize such preference in accordance with applicable law.</p>
                    <ol>
                        <br></br>
                        <li><strong> Disabling Geo-location Tracking</strong></li>
                    </ol>
                    <p>If you have previously granted us permission to collect your precise GPS location information and
                        you no longer wish for us and our service providers to collect and use such information, you
                        have the option to disable the location features on your device. This can typically be done by
                        accessing the settings of your device's operating system.</p>
                    <p>For iOS devices (Apple phones), you can go to Settings &gt; Privacy &gt; Location Services and
                        toggle off the location services for our app or for all apps on your device.</p>
                    <p>For Android devices, the process may vary slightly depending on the manufacturer and version of
                        the operating system. Generally, you can go to Settings &gt; Location &gt; App permissions and
                        disable location access for our app or for all apps.</p>
                    <p>It's important to note that if you disable the location features on your device, it may impact
                        your ability to access certain services, content, and features provided through our Platform.
                        For example, you may not be able to receive location-based recommendations, find nearby
                        services, or access location-specific content.</p>
                    <ol>
                        <br></br>
                        <li><strong> Notice of Financial Incentives</strong></li>
                    </ol>
                    <p>At Catervalley, we may offer certain rewards or incentives to users who participate in our
                        Catervalley text message programs. These incentives are designed to enhance your experience and
                        provide additional value to your engagement with our platform. Participation in these programs
                        is entirely voluntary, and you have the right to withdraw from the program at any time. If you
                        choose not to participate or wish to discontinue participation, you can opt-out of the text
                        message program by following the instructions outlined in our SMS Terms.</p>
                    <br></br>
                    <p>The specific rewards or incentives offered, if any, will be communicated to you when you register
                        for an eligible Catervalley text message program. It's important to note that the monetary value
                        of the reward or incentive is based on a reasonable approximation of the value derived from
                        participation in our text message program. This approximation considers various factors,
                        including:</p>
                    <br></br>
                    <ol>
                        <li>Revenue generated by Catervalley through our text message programs.</li>
                        <li>Expenses incurred in operating these programs.</li>
                        <li>Improvement of products and services based on insights obtained through these programs.</li>
                    </ol>
                    <p>We prioritize transparency in our incentive programs and strive to provide valuable benefits to
                        our users while ensuring compliance with applicable laws and regulations. For more information
                        about our SMS Terms and how to opt-out of the text message program, please refer to the relevant
                        sections on our platform.</p>
                    <p>Regarding email promotions, while this notice specifically addresses Catervalley's text message
                        programs, we also offer promotions and incentives through email campaigns. Similar to our text
                        message programs, participation in email promotions is voluntary, and users have the option to
                        opt-out of receiving promotional emails at any time. Our goal is to deliver relevant and
                        valuable offers to our users across various communication channels, ensuring a positive and
                        rewarding experience.</p>
                    <ol start="7">
                        <br></br>
                        <li><strong><u>INTERNATIONAL USERS</u></strong></li>
                    </ol>
                    <p>Catervalley is based in the United States and processes data within the U.S. We may also engage
                        service providers and partners in other countries to process your data. By using our Platform,
                        you consent to the transfer, storage, and processing of your information both within and outside
                        your country of residence.</p>
                    <p>Please be aware that data protection laws vary by country. Regardless of location, Catervalley
                        implements appropriate safeguards to protect your data in compliance with applicable
                        regulations.</p>
                    <ol start="8">
                        <br></br>
                        <li><strong><u>HOW WE PROTECT YOUR INFORMATION</u></strong></li>
                    </ol>
                    <p>We are committed to safeguarding your information through various security measures:</p>
                    <ol>
                        <li>Encryption: We use encryption protocols for data transmission and storage.</li>
                        <li>Access Controls: We restrict access to authorized personnel and implement strict controls.
                        </li>
                        <li>Regular Audits: We conduct security assessments to identify and address vulnerabilities.
                        </li>
                        <li>Data Minimization: We collect and retain only necessary information.</li>
                        <li>Employee Training: Our staff receives comprehensive data protection training and is bound by
                            confidentiality obligations.
                        </li>
                    </ol>
                    <p>While we strive for the highest security standards, no method of internet transmission or
                        electronic storage is 100% secure. We cannot guarantee absolute security for information
                        transmitted to or stored on our Platform.</p>
                    <p>We continuously update our security protocols to address evolving threats and technological
                        advancements.</p>
                    <br></br>
                    <p><strong>9.DATA RETENTION</strong></p>
                    <p>We retain your personal information until you cancel your Catervalley account or as long as
                        necessary to:</p>
                    <ul>
                        <li>Fulfill our contractual obligations</li>
                        <li>Comply with legal requirements</li>
                        <li>Meet the purposes outlined in this Privacy Policy</li>
                    </ul>
                    <p>When data is no longer needed, we either de-identify or securely erase it. We may retain certain
                        information to comply with legal obligations or resolve disputes, always ensuring secure
                        storage.</p>
                    <br></br>
                    <p><strong><u>10.CHANGES</u></strong><strong><u> TO OUR PRIVACY POLICY</u></strong></p>
                    <p>If you have any questions, concerns, or comments regarding this Privacy Policy or our practices
                        regarding your personal information, please feel free to contact us. You can reach us via email
                        at support@Catervalley.com. Alternatively, you can contact us by mail at:</p>
                    <br></br>
                    <p>Catervalley, Inc.</p>
                    <p>Attn: Legal</p>
                    <p>303 Almaden Blvd, STE 160</p>
                    <p>San Jose, CA 95110</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                </div>
            </div>
        </div>
    );
}

export default Privacy;
