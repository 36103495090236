import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {listFavorite} from "../../../service/FavoriteService";

const initialState = {
    loading: false,
    data: []
}

export const getFavorite = createAsyncThunk(
    'getFavorite',
    async (data) => {
        const response = await listFavorite(data);
        return response.data;
    }
)

const favoriteSlice = createSlice({
    name: 'favorite',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFavorite.fulfilled, (state, action) => {
                state.loading = false
                state.data = action.payload.data
            })
            .addCase(getFavorite.pending, (state) => {
                state.loading = true
            })
            .addCase(getFavorite.rejected, (state) => {
                state.loading = false
            })
    }
})

export default favoriteSlice.reducer