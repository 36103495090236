import toast from "../components/toast";
import Notification from "../components/Notification";
import React from "react";

export const showToast = (msg, title, type) => {
    toast.push(
        <Notification className={"border-gray-300 shadow-sm "} type={type} duration={2000} title={title}>
            {msg}
        </Notification>,
        {
            placement: 'top-end',
        }
    )
}