import { createSlice } from '@reduxjs/toolkit';

export const selectionSlice = createSlice({
    name: 'selection',
    initialState: {
        selectedItems: [],
    },
    reducers: {
        setSelection: (state, action) => {
            const { id, branchId, productId } = action.payload;
            if (state.selectedItems.some(item => item.branchId !== branchId)) {
                state.selectedItems = [{ id, branchId, productId }];
            } else {
                const existingIndex = state.selectedItems.findIndex(item => item.id === id);
                if (existingIndex !== -1) {
                    // Eğer seçili ise sil
                    state.selectedItems.splice(existingIndex, 1);
                } else {
                    // Seçili değilse ekle
                    state.selectedItems.push({ id, branchId, productId });
                }
            }
        },
    },
});

export const { setSelection } = selectionSlice.actions;
export default selectionSlice.reducer;
