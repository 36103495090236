import {Fragment, useState} from 'react'
import {Outlet} from "react-router-dom";
import { Dialog} from '@headlessui/react'
import {
    Bars3Icon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import {companyInfo} from "../../utils/caterValleyInformation";
import Footer from "../footer";
import {setActiveTab, setAuthModal} from "../../pages/Auth/store";
import {useDispatch, useSelector} from "react-redux";
import Auth from "../../pages/Auth";

const navigation = [
    {name: 'Support & Contact', href: 'tel:+14082175111', current: false},
]


export default function WebsiteHeader() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const dispatch = useDispatch();
    const authState = useSelector((state) => state.auth);

    return (
        <div className={"bg-gradient-to-t from-indigo-100 via-gray-200 to-white"}>
            <header>
                <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
                    <a href="/home" className="-m-1.5 p-1.5">
                        <span className="sr-only">Your Company</span>
                        <img
                            className="h-8 w-auto"
                            src={companyInfo.logo}
                            alt=""/>
                    </a>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-12">
                        {authState.logged && (
                            <>
                                <a href={"/profile"} className="text-sm font-semibold leading-6 text-gray-900">
                                    My Profile
                                </a>
                            </>
                        )}

                        {!authState.logged && (
                            <>
                                <button onClick={() => {
                                    dispatch(setActiveTab("login"));
                                    dispatch(setAuthModal(true));
                                }} className="text-sm font-semibold leading-6 text-gray-900">
                                    Sign In
                                </button>

                                <button onClick={() => {
                                    dispatch(setActiveTab("register"));
                                    dispatch(setAuthModal(true));
                                }} className="text-sm font-semibold leading-6 text-gray-900">
                                    Sign Up
                                </button>
                            </>
                        )}


                        {navigation.map((item) => (
                            <a key={item.name} href={item.href}
                               className="text-sm font-semibold leading-6 text-gray-900">
                                {item.name}
                            </a>
                        ))}
                        <a href="/home" className="text-sm font-semibold leading-6 text-gray-900">
                            Order <span aria-hidden="true">&rarr;</span>
                        </a>
                    </div>
                </nav>
                <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-10"/>
                    <Dialog.Panel
                        className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <a href="/home" className="-m-1.5 p-1.5">
                                <span className="sr-only">CaterValley</span>
                                <img
                                    className="h-8 w-auto"
                                    src={companyInfo.logo}
                                    alt=""
                                />
                            </a>
                            <button
                                type="button"
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                                <div className="py-6">
                                    <a
                                        href="/home"
                                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        Order
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Dialog.Panel>
                </Dialog>
            </header>
            <Auth/>
            <main className={"mx-auto max-w-7xl px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8 "}>
                <Outlet/>
            </main>
            <Footer/>
        </div>
    )
}
