import CaterButton from "../../../components/CaterButton";
import {useDispatch, useSelector} from "react-redux";
import CaterFormTextArea from "../../../components/CaterFormTextArea/CaterFormTextArea";
import SearchAddress from "./SearchAddress";
import {setErrorMessage, setModal, setNote, setSearchInput, setSuiteNumber} from "./store";
import {Field, Form, Formik} from "formik";
import CaterFormInput from "../../../components/CaterFormInput/CaterFormInput";


const AddressForm = () => {
    const dispatch = useDispatch();
    const addressState = useSelector((state) => state.address);

    const AddressFormElement = () => (
        <div className={"w-full py-4 px-4"}>
            <div className={"w-full mb-4"}>
                <CaterButton className={"w-full"} onClick={() => dispatch(setSearchInput(!addressState.searchInput))}>
                    {addressState.data.title || addressState.data.address || "Select Address"}
                </CaterButton>
            </div>

            <Formik
                initialValues={{
                    suiteNumber: addressState.suiteNumber || "",
                    note: addressState.note || ""
                }}
                onSubmit={(values) => {
                    if (!addressState.data.address) {
                        dispatch(setErrorMessage(true));
                    } else {
                        dispatch(setErrorMessage(false))
                        dispatch(setSuiteNumber(values.suiteNumber));
                        dispatch(setNote(values.note));
                        dispatch(setModal(false))
                    }
                }}>
                <Form>
                    <div className={"w-full mb-4"}>
                        <label htmlFor={"suite-number"}>
                            Suite (Optional)
                        </label>
                        <Field
                            id={"suiteNumber"}
                            name={"suiteNumber"}
                            key={"suiteNumber"}
                            placeholder={"Suite Number"}
                            component={CaterFormInput}
                        />
                    </div>

                    <div className={"w-full mb-4"}>
                        <label htmlFor={"note"}>
                            Note (Optional)
                        </label>
                        <Field
                            id={"note"}
                            name={"note"}
                            key={"note"}
                            placeholder={"You can add additional notes for directions or the courier"}
                            component={CaterFormTextArea}
                        />
                    </div>

                    <div className={"w-full flex"}>
                        <CaterButton
                            className={"ml-auto"}
                            color={"indigo"}
                            type={"submit"}
                        >
                            Save
                        </CaterButton>
                    </div>
                </Form>
            </Formik>


        </div>
    )

    return (
        <>
            {!addressState.searchInput && (
                <AddressFormElement/>
            )}

            {addressState.searchInput && (
                <SearchAddress/>
            )}
        </>
    )
}

export default AddressForm;
