import ApiService from "./ApiService";

export async function apiLogin(data) {
    return ApiService.fetchData({
        url: '/login',
        method: 'post',
        data,
    })
}

export async function apiRegister(data) {
    return ApiService.fetchData({
        url: '/register',
        method: 'post',
        data,
    })
}

export async function apiLogout(data) {
    return ApiService.fetchData({
        url: '/auth/sign-out',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/recovery-password',
        method: 'post',
        data
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data
    })
}

export async function apiRefreshToken(data) {
    return ApiService.fetchData({
        url: '/refresh-token',
        method: 'post',
        data
    })
}

export async function apiUpdateUser(data) {
    return ApiService.fetchData({
        url: '/update',
        method: 'post',
        data
    })
}

export async function apiConfirmMail() {
    return ApiService.fetchData({
        url: '/send-verify-mail',
        method: 'post',
    })
}

export async function apiVerifyMail(data) {
    return ApiService.fetchData({
        url: '/verify-mail',
        method: 'post',
        data
    })
}

export async function apiCheckUser() {
    return ApiService.fetchData({
        url: '/check-user',
        method: 'post',
    })
}

export async function apiListEmployee() {
    return ApiService.fetchData({
        url: '/list-employee',
        method: 'post',
    })
}

export async function apiListCard() {
    return ApiService.fetchData({
        url: '/c-list',
        method: 'post',
    })
}


export async function apiSaveCard(data) {
    return ApiService.fetchData({
        url: '/c-save',
        method: 'post',
        data
    })
}

export async function apiDeleteCard(data) {
    return ApiService.fetchData({
        url: '/c-delete',
        method: 'post',
        data
    })
}

export async function apiMakePrimary(data) {
    return ApiService.fetchData({
        url: '/c-primary',
        method: 'post',
        data
    })
}
