import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {apiGetOrder, apiGetTrackingOrder, apiListOrder, apiListPreviousOrder} from "../../../../service/OrderService";

const initialState = {
    loading: false,
    data: {},
    list: {
        loading: false,
        data: []
    }
}

export const getOrder = createAsyncThunk(
    'getOrder',
    async (data) => {
        const response = await apiGetOrder(data);
        return response.data
    }
)

export const getOrderList = createAsyncThunk(
    'orderList',
    async () => {
        const response = await apiListOrder();
        return response.data;
    }
)

export const getPreviousOrderList = createAsyncThunk(
    'previousOrderList',
    async (data) => {
        const response = await apiListPreviousOrder(data);
        return response.data;
    }
)

export const getTrackingOrder = createAsyncThunk(
    'getTrackingOrder',
    async (data) => {
        const response = await apiGetTrackingOrder(data);
        return response.data;
    }
)



const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        resetOrderInformationState: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOrder.fulfilled, (state, action) => {
                state.loading = false
                state.data = action.payload.data
            })
            .addCase(getOrder.pending, (state) => {
                state.loading = true
            })
            .addCase(getOrder.rejected, (state) => {
                state.loading = false
            })
            .addCase(getTrackingOrder.fulfilled, (state, action) => {
                state.loading = false
                state.data = action.payload.data
            })
            .addCase(getTrackingOrder.pending, (state) => {
                state.loading = true
            })
            .addCase(getTrackingOrder.rejected, (state) => {
                state.loading = false
            })
            .addCase(getOrderList.fulfilled, (state, action) => {
                state.list.loading = false
                state.list.data = action.payload.data
            })
            .addCase(getOrderList.pending, (state) => {
                state.list.loading = true
            })
            .addCase(getOrderList.rejected, (state) => {
                state.list.loading = false
            })
            .addCase(getPreviousOrderList.fulfilled, (state, action) => {
                state.list.loading = false
                state.list.data = action.payload.data
            })
            .addCase(getPreviousOrderList.pending, (state) => {
                state.list.loading = true
            })
            .addCase(getPreviousOrderList.rejected, (state) => {
                state.list.loading = false
            })
    }
})

export const {
    resetOrderInformationState
} = orderSlice.actions

export default orderSlice.reducer
