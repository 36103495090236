import CaterFormInput from "../../../components/CaterFormInput/CaterFormInput";
import usePlacesAutocompleteService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import {IoLocationOutline} from "react-icons/io5";
import {TiArrowRightOutline} from "react-icons/ti";
import {useDispatch, useSelector} from "react-redux";
import {setAddress, setSearchInput} from "./store";
import {resetDraftOrderState} from "../../Order/Cart/store";
import {resetPaymentState} from "../../Order/Checkout/Payment/store";
import {getListCatering, getListCateringCorporate} from "../../Home/store";

const apikey = "AIzaSyCFNFtYje__a04wEZEuv88O3oNwCR602Ac";

const SearchAddress = () => {
    const dispatch = useDispatch();
    const authState = useSelector((state) => state.auth);


    const {
        placesService,
        placePredictions,
        getPlacePredictions,
    } = usePlacesAutocompleteService({
        apiKey: apikey,
        libraries: ["places"],
        options: {
            componentRestrictions: {country: ['US']},
            types: ['geocode']
        },
        language: "en"
    });


    const getAddressFullInfo = async (placeId) => {
        return new Promise((resolve, reject) => {
            if (placePredictions.length) {
                placesService?.getDetails(
                    {
                        placeId: placeId
                    },
                    (placeDetails) => resolve(placeDetails)
                )
            } else {
                resolve(null);
            }
        })
    }

    return (
        <>
            <div className={"px-5"}>
                <CaterFormInput
                    placeholder={"Enter Address"}
                    onChange={(evt) => {
                        getPlacePredictions({input: evt.target.value});
                    }}
                />
            </div>


            {placePredictions.length <= 0 && (
                <div className={"grid grid-rows-2 justify-center items-center w-full py-10 px-5"}>
                    <div className={"flex w-full items-center justify-center"}>
                        <IoLocationOutline className={"text-gray-700"} size={50}/>
                    </div>
                    <div className={"flex w-full items-center ml-1 justify-center"}>
                        <p className={"text-lg text-gray-600"}>Enter Address</p>
                    </div>
                </div>
            )}

            <div className={"px-5"}>
                {placePredictions.map((item) => (
                    <div
                        className={"px-3 py-3 w-full border-b flex items-center gap-5 hover:bg-gray-50 cursor-pointer active:bg-gray-200"}
                        onClick={async () => {
                            const addressInformation = await getAddressFullInfo(item.place_id);
                            dispatch(setAddress({
                                title: "",
                                address: item.structured_formatting.main_text,
                                city: item.terms[item.terms.length - 3]?.value,
                                state: item.terms[item.terms.length - 2]?.value,
                                placeId: item.place_id,
                                postalCode: addressInformation.address_components[addressInformation.address_components.length - 1]?.long_name
                            }));

                            if (authState.logged && authState.data?.user?.isCorporate) {
                                dispatch(getListCateringCorporate({
                                    fullAddress: item.structured_formatting.main_text,
                                    city: item.terms[item.terms.length - 3]?.value,
                                    state: item.terms[item.terms.length - 2]?.value,
                                    zipCode: addressInformation.address_components[addressInformation.address_components.length - 1]?.long_name
                                }));
                            } else {
                                dispatch(getListCatering({
                                    fullAddress: item.structured_formatting.main_text,
                                    city: item.terms[item.terms.length - 3]?.value,
                                    state: item.terms[item.terms.length - 2]?.value,
                                    zipCode: addressInformation.address_components[addressInformation.address_components.length - 1]?.long_name
                                }));
                            }


                            dispatch(resetDraftOrderState());
                            dispatch(resetPaymentState());
                            dispatch(setSearchInput(false))
                        }}
                    >
                        <div className={"mr-auto flex items-center"}>
                            <IoLocationOutline className={"text-lg"}/>
                            <div className={"ml-5"}>
                                <p className={"font-semibold"}>{item.structured_formatting.main_text}</p>
                                <p className={"text-gray-500"}>{item.structured_formatting.secondary_text}</p>
                            </div>
                        </div>

                        <TiArrowRightOutline className={"ml-auto text-lg"}/>
                    </div>
                ))}
            </div>

        </>
    )
}

export default SearchAddress;
