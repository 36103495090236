import {USDollar} from "../../../../utils/moneyFormat";
import {useDispatch, useSelector} from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import {setDraftOrder, setItems} from "../../Cart/store";
import CaterButton from "../../../../components/CaterButton";
import {FiMinus, FiPlus} from "react-icons/fi";
import Loading from "../../../../components/Loading/Loading";
import {apiSaveDraft, apiSaveDraftGuest} from "../../../../service/OrderService";

const SummaryItems = ({mtValue}) => {
    const dispatch = useDispatch();
    const draftOrderState = useSelector((state) => state.draftOrder);
    const payment = useSelector((state) => state.payment);


    const removeItem = async (id) => {
        const itemsNew = cloneDeep(draftOrderState.draftOrder?.draftOrderItems);
        dispatch(setItems(itemsNew.filter(item => item.id !== id)));
    }

    const calculateTotalPrice = (data) => {
        return data.options.reduce((total, group) => {
            const groupTotal = group.selectedOptions.reduce((groupSum, option) => groupSum + option.price, 0);
            return total + groupTotal || 0;
        }, 0);
    };

    const updateQty = async (id, type) => {
        const itemsNew = cloneDeep(draftOrderState.draftOrder?.draftOrderItems);
        const itemIndex = itemsNew.findIndex(item => item.id === id);

        if (itemIndex !== -1) {
            if (itemsNew[itemIndex]["itemCount"] <= 1 && type === "min") {
                return;
            }

            if (type === "min") {
                itemsNew[itemIndex]["itemCount"] -= 1;
            }

            if (type === "inc") {
                itemsNew[itemIndex]["itemCount"] += 1;
            }

            itemsNew[itemIndex]["itemTotalPrice"] = (itemsNew[itemIndex]["itemPrice"] + calculateTotalPrice(itemsNew[itemIndex]["itemMeta"])) * itemsNew[itemIndex]["itemCount"];

            dispatch(setItems(itemsNew));
        }
    }


    return (
        <div className={mtValue || "mt-8"}>
            <div className="flow-root">
                <ul role="list" className="-my-6 divide-y divide-gray-200">
                    {draftOrderState.draftOrder?.draftOrderItems?.map((product) => (
                        <li key={product.id} className="flex py-6">
                            <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                <img
                                    src={product.itemPhoto}
                                    alt={product.name}
                                    className="h-full w-full object-cover object-center"
                                />
                            </div>

                            <div className="ml-4 flex flex-1 flex-col">
                                <div>
                                    <div className="flex justify-between text-base font-medium text-gray-900">
                                        <h3>
                                            <a onClick={() => alert("test")}>{product.itemName}</a>
                                        </h3>
                                        <p className="ml-4">{USDollar.format(product.itemTotalPrice)}</p>
                                    </div>
                                    {product.itemMeta?.options.length > 0 && (
                                        <div className=" text-gray-600 bg-gray-100 rounded-xl mb-3 mt-2 py-2 px-2">
                                            {product.itemMeta?.options?.map((option, index) => (
                                                <div>
                                                    <p className={`font-semibold`}>{option.name}</p>
                                                    {option.selectedOptions?.map((selectOption, _index) => (
                                                        <div
                                                            className={`flex items-center ${product.itemMeta?.options.length - 1 > 0 && option.selectedOptions.length - 1 === _index ? "mb-2" : ""}`}
                                                            key={_index}>
                                                            <svg viewBox="0 0 2 2"
                                                                 className="mx-2 inline h-0.5 w-0.5 fill-current"
                                                                 aria-hidden="true">
                                                                <circle cx={1} cy={1} r={1}/>
                                                            </svg>
                                                            <p className={"mr-1"}>{selectOption.name}</p>
                                                            <div className={"border-l"}>
                                                                <p className={"text-xs mt-0.5 ml-1"}>{(selectOption.price === 0 ? "Free" : USDollar.format(selectOption.price))}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                    {product.itemMeta?.options.length <= 0 && (
                                        <>
                                            <p className={"text-gray-500"}>{product.itemDescription}</p>
                                        </>
                                    )}
                                </div>

                                <div className="flex flex-1 items-center justify-between text-sm mt-1 sm:mt-2">
                                    <Loading loading={payment.loading} type={"cover"}>
                                        <div>
                                            <div
                                                className={"inline-flex mt-1 items-center justify-center gap-5 h-full w-full "}>
                                                <CaterButton icon={<FiMinus/>}
                                                             onClick={() => updateQty(product.id, "min")}/>
                                                <p>{product.itemCount}</p>
                                                <CaterButton icon={<FiPlus/>}
                                                             onClick={() => updateQty(product.id, "inc")}/>
                                            </div>
                                        </div>
                                    </Loading>


                                    <div className="flex mt-1">
                                        <button
                                            type="button"
                                            className="font-medium text-indigo-600 hover:text-indigo-500"
                                            onClick={() => removeItem(product.id)}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default SummaryItems;
