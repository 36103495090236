import {useDispatch, useSelector} from "react-redux";
import ScrollBar from "../../../components/ScrollBar";
import Loading from "../../../components/Loading/Loading";
import { BiDetail} from "react-icons/bi";
import {GiCancel} from "react-icons/gi";
import {USDollar} from "../../../utils/moneyFormat";
import CountdownTimer from "../../Home/CountdownTimer";
import CaterButton from "../../../components/CaterButton";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import {useState} from "react";
import {showToast} from "../../../utils/showToast";
import {corporateDelOrder} from "../../../service/CorporateService";
import {listCorporateOrder, listCorporateSummaryOrder, listWeekly} from "../store";
import moment from "moment/moment";
import {useParams} from "react-router-dom";

const CorporateCart = () => {
    const dispatch = useDispatch();
    const corporateState = useSelector((state) => state.corporate);
    const [cancelModal, setCancelModal] = useState(false);
    const [cancelOrderDate, setCancelOrderDate] = useState("");
    const {orderDate} = useParams();

    const cancelOrderConfirm = async () => {
        try {
            const response = await corporateDelOrder({
                orderDate: cancelOrderDate
            });

            if (response.data.result) {
                if (window.location.href.includes("order-summary")) {
                    dispatch(listCorporateOrder({
                        orderDate: orderDate
                    }));
                }
               dispatch(listCorporateSummaryOrder());
               dispatch(listWeekly())
            } else {
                showToast(response.data.userMessage, "Error", "danger")
            }

            setCancelModal(false);
            setCancelOrderDate("");
        } catch (e) {
           showToast(e.message, "Error", "danger")
           setCancelModal(false);
           setCancelOrderDate("");
        }
    }

    const cancelOrderCancel = async () => {
        setCancelModal(false);
    }

    return (
        <div className={"sticky top-2 bg-white"}>
            <div className={"flex gap-2 items-center border-b p-2"}>
                <p className={"text-lg font-semibold"}>Summary</p>
                <CaterButton className={"!text-xs"} onClick={() => window.location.href = '/order-summary/all'}>All</CaterButton>
            </div>

            <Loading loading={corporateState.orderSummaryLoading}>
                <div className={"overflow-auto h-[92vh]"}>
                    <ScrollBar autohidden={true}>
                        {corporateState.orderSummaryList && corporateState.orderSummaryList?.map((order, index) => (
                            <div
                                key={index}
                                className={"border py-2 px-2 mb-2 mt-2 mr-3 rounded-xl"}
                            >
                                <div className={"border-b"}>
                                    <p className={"text-gray-600 font-semibold mb-1"}>{moment.utc(order?.weekDate).format('MM/DD/YYYY')}</p>
                                </div>

                                {order.orderItemCount === 0 && order.allOrderTotalPrice === 0 ? (
                                    <div className={"flex justify-center items-center mt-2 "}>
                                        <p className={"text-gray-500 h-full"}>Not available</p>
                                    </div>
                                ) : (
                                    <>
                                        <div className={"mt-1 mb-1"}>
                                            <div className={"flex justify-center"}>
                                                <p>Cut-Off Time</p>
                                                <CountdownTimer targetDate={order.cutOffTime}
                                                                additionalClassName={"!text-sm ml-auto !mb-0"}
                                                                overTimeText={"Time's Up!"}/>
                                            </div>

                                            <div className={"flex justify-center"}>
                                                <p>Item / Employee</p>
                                                <p className={"ml-auto"}>{order.orderItemCount} / {order.employeeCount}</p>
                                            </div>

                                            <div className={"flex justify-center"}>
                                                <p>Total Tax</p>
                                                <p className={"ml-auto"}>{USDollar.format(order.allOrderTax)}</p>
                                            </div>

                                            <div className={"flex justify-center"}>
                                                <p>Total Price (Inc. Tax)</p>
                                                <p className={"ml-auto"}> {USDollar.format(order.allOrderTotalPrice)}</p>
                                            </div>
                                        </div>

                                        <div className={"border-t"}>
                                            <div className={`grid  grid-cols-${order.status === "DRAFT" ? "2" : "1"} gap-2 mt-2`}>
                                                <CaterButton icon={<BiDetail/>}
                                                             className={"!text-xs"} onClick={() => window.location.href = '/order-summary/' + order.weekDate}>Summary</CaterButton>

                                                {order.status === "DRAFT" && (
                                                    <CaterButton icon={<GiCancel/>} className={"!text-xs"} onClick={() => {
                                                        setCancelModal(true);
                                                        setCancelOrderDate(order.weekDate);
                                                    }}>
                                                        Cancel
                                                    </CaterButton>
                                                )}

                                            </div>
                                        </div>
                                    </>
                                )}


                            </div>
                        ))}
                    </ScrollBar>
                </div>
            </Loading>

            <ConfirmDialog title={"Are you sure?"} type={"danger"} isOpen={cancelModal} onConfirm={() => cancelOrderConfirm()} onCancel={() => cancelOrderCancel()} confirmText={"Delete Order(s)"}>
                Are you sure you want to cancel orders dated {cancelOrderDate}?
            </ConfirmDialog>

        </div>
    )
}

export default CorporateCart;
